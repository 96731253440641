.banner {
  margin-top: 0px;
  padding: 80px 0 100px 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.description-job ul li {
  padding-bottom: 10px;
}

.banner .tagline {
  font-weight: bolder !important;
  letter-spacing: 0.8px;
  /* background: linear-gradient(
    90.21deg,
    rgba(72, 3, 68, 0.5) -5.91%,
    rgba(246, 3, 153, 0.5) 111.58%
  ); */
  border-bottom: 1px solid #a619b3;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 1.6rem;
}
.modal-backdrop.dark {
  background-color: rgba(0, 0, 0, 0.3);
}

.lottie-filter {
  filter: hue-rotate(280deg);
  opacity: 0.5; /* Puedes ajustar el valor del ángulo según tu preferencia */
}
.description-job ul li b {
  /* color: #060706; */
  text-transform: uppercase;
  /* text-decoration: underline; */
}

.dark-background {
  position: fixed;
  top: 150;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fondo oscuro con opacidad */
  z-index: 999; /* Asegúrate de que esté por encima de otros elementos */
  pointer-events: auto; /* Habilita eventos de puntero en este elemento */
}
.banner h1 {
  font-size: 40px;
  font-weight: 200;
  line-height: 1;
  display: block;
}
.cargo {
  font-size: 40px !important;
  font-weight: 200;
  line-height: 1;
  display: block;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.name-secoundary {
  font-weight: 700;
  line-height: 1;
  display: block;
  border-bottom: 1px solid rgb(223, 169, 206);
  width: 200px;
  margin-bottom: 8px;
  padding: 3px;
  margin-top: 10px;
}

.botones-experiencia {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.fecha-trabajo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid rgb(223, 169, 206);
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}
.button-number {
  margin-top: 10px;
}

.fecha-trabajo2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
}
.milottie {
  width: 500px;
  height: 250px;
  margin-top: 10px;
}
.span-name {
  color: rgb(209, 154, 191);
}
.description-job {
  color: #f3f3f3;
  font-size: 16px;
  width: 100%;
  padding-bottom: 10px;
  font-weight: 400 !important;
  opacity: 0.9;
}

.banner p {
  color: #b8b8b8;
  font-size: 16px;
  line-height: 1.5em;
  width: 100%;
  padding-bottom: 10px;
}
.rotate-skills {
  opacity: 0.7;
  font-weight: 200 !important;
  padding-bottom: 20px;
}
.modal-header {
  background-color: #f0edef;
  border-radius: 3px;
}
.modal-title {
  color: #751a5f;
  /* font-weight: 400 !important; */
}

.despcription-banner {
  color: #b8b8b8;
  font-size: 16px !important;
  line-height: 1.5em;
  width: 100%;
  padding-bottom: 0px !important;
}

.banner button {
  color: #fff;
  font-weight: 700;
  margin-top: 40px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  transition: 0.3s ease-in-out;
  line-height: 1;
  margin-right: 10px;
}

.banner button:hover svg {
  margin-left: 25px;
}
.mail-banner {
  font-weight: 700;
  margin-top: 40px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  animation: none !important;
}
/* .banner img {
  animation: updown 3s linear infinite;
} */

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #751a5f;
  opacity: 0.7;
  font-size: 22px;
  color: #aa4390;
}

.card7 {
  width: 190px;
  height: 254px;
  border-radius: 20px;
  background: #f5f5f5;
  position: relative;
  padding: 1.8rem;
  border: 2px solid #c3c6ce;
  transition: 0.5s ease-out;
  overflow: visible;
}

.card7-details {
  color: black;
  height: 100%;
  gap: 0.5em;
  display: grid;
  place-content: center;
}

.card7-button {
  transform: translate(-50%, 125%);
  width: 60%;
  border-radius: 1rem;
  border: none;
  background-color: #cdcfd0;
  color: #fff;
  font-size: 5rem;
  padding: 0.5rem 1rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  opacity: 0;
  transition: 0.3s ease-out;
}

/*Text*/
.text-title {
  font-size: 1.5em;
}

/*Hover*/
.card7:hover {
  border-color: #008bf8;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
}

.card5:hover .card-button {
  transform: translate(-50%, 50%);
  opacity: 1;
}

.button3 {
  --color: #c88dad;
  padding: 0.5em 1em;
  background-color: transparent;
  border-radius: 2px;
  margin-top: 0px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 100 !important;
  font-size: 5px;
  border: 1px solid;
  font-family: inherit;
  text-transform: uppercase;
  color: var(--color);
  z-index: 1;
  font-size: 18px;
}

.button3::before,
.button3::after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--color);
  transition: 1s ease;
}

.button3::before {
  top: -1em;
  left: -1em;
}

.button3::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
}

.button3:hover::before,
.button3:hover::after {
  height: 410px;
  width: 410px;
}

.button3:hover {
  color: rgb(10, 25, 30);
}

.button3:active {
  filter: brightness(0.8);
}

@media screen and (max-width: 767px) {
  .banner {
    padding-top: 20px;
    padding-bottom: 60px;
  }
  .banner .tagline {
    margin-bottom: 10px;
  }
  .mail-banner {
    display: none;
  }
  .lottie-filter {
    filter: saturate(0);
    opacity: 0.5;
  }
  .banner button svg {
    opacity: 0.5;
  }
  .banner button {
    opacity: 0.8;
  }
}
